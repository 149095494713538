import {
    ClerkProvider,
    SignedIn,
    SignedOut,
    RedirectToSignIn,
    SignIn,
    SignUp,
    UserButton,
    useUser,
} from "@clerk/clerk-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./Home.js";
import Login from "./Login.js";
import Inbox from "./Inbox.js";
import Settings from "./Settings.js";
import SignUpModal from "./SignUpModal";
import NavBar from "./NavBar";
import ParentHome from "./ParentHome.js";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key!");
}
const clerkPublishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function ProtectedSignedIn() {
    const { user } = useUser();
    return (
        <div style={{ backgroundColor: "white" }}>
            <h1 style={{ color: "red" }}>This page is protected!</h1>
            <p>Welcome, {user.firstName}</p>
            <UserButton />
        </div>
    );
}

function Protected() {
    return (
        <div>
            <SignedIn>
                <ProtectedSignedIn />
            </SignedIn>
            <SignedOut>
                <RedirectToSignIn />
            </SignedOut>
        </div>
    );
}

const myRoutes = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/old-outdated-login", // This is no longer going to be used, so rename it to make things less confusing
        element: <Login />,
    },
    {
        path: "/Inbox",
        element: <Inbox />,
    },
    {
        path: "/Settings",
        element: <Settings />,
    },
    {
        path: "/Parents",
        element: <ParentHome />,
    },

    // Clerk specific routes
    { path: "/sign-in/*", element: <SignIn routing="path" path="/sign-in" /> },
    { path: "/sign-up/*", element: <SignUp routing="path" path="/sign-up" /> },

    // Protected page - a user must be logged in to view this page
    {
        path: "/protected",
        element: <Protected />,
    },
]);

function App() {
    return (
        <ClerkProvider
            publishableKey={clerkPublishableKey}
            navigate={(to) => myRoutes.navigate(to)}
        >
            <div>
                <NavBar />
                <RouterProvider router={myRoutes} />
            </div>
        </ClerkProvider>
    );
}

export default App;
